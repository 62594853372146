import { AVAILABLE, UNDER_CONSTRUCTION, UNAVAILABLE } from '@/constants/signup-queries.js'

const ERROR_TRACK = 'error_track'

const ADDRESS_CHECK_AUTOCOMPLETE_SELECT = 'address_check_autocomplete_select'
const ADDRESS_CHECK_SUBMIT = 'address_check_submit'
const ADDRESS_CHECK_CHANGE = 'address_check_change'

const INPUT_BLUR = 'input_blur'
const INPUT_FOCUS = 'input_focus'

const SERVICE_PLAN_SELECTED = 'service_plan_selected'

const FIBER_STATUS_DETERMINED = 'fiber_status_determined'

const SIGNUP_FORM_SUBMIT = 'signup_form_submit'

const SIGNUP_FORM_SUBMIT_AVAILABLE = 'signup_form_submit_available'
const SIGNUP_FORM_SUBMIT_UNAVAILABLE = 'signup_form_submit_unavailable'
const SIGNUP_FORM_SUBMIT_UNDER_CONSTRUCTION = 'signup_form_submit_under_construction'

const signupFormSubmitEvents = {
	[AVAILABLE]: SIGNUP_FORM_SUBMIT_AVAILABLE,
	[UNAVAILABLE]: SIGNUP_FORM_SUBMIT_UNAVAILABLE,
	[UNDER_CONSTRUCTION]: SIGNUP_FORM_SUBMIT_UNDER_CONSTRUCTION
}

const OPEN_HEADER_TRAY = 'open_header_tray'
const MOBILE_NAV_CLICK = 'mobile_nav_click'

const FOOTER_NAV_CLICK = 'footer_nav_click'
const FOOTER_NAV_HOVER = 'footer_nav_hover'

const TESTIMONIAL_CLICK = 'testimonial_click'
const TESTIMONIAL_HOVER = 'testimonial_hover'

const CAROUSEL_SLIDE_CHANGED = 'carousel_slide_changed'

const FAQ_CLICK = 'faq_click'
const FAQ_HOVER = 'faq_hover'

const BUSINESS_FORM_SUBMIT = 'business_form_submit'

const CONTACT_CALL_US_CLICK = 'contact_call_us_click'
const CONTACT_FORM_SUBMIT = 'contact_form_submit'

const LIVE_CHAT_WIDGET_CLICK = 'live_chat_widget_click'

export {
	ADDRESS_CHECK_AUTOCOMPLETE_SELECT,
	ADDRESS_CHECK_SUBMIT,
	ADDRESS_CHECK_CHANGE,
	ERROR_TRACK,
	INPUT_BLUR,
	INPUT_FOCUS,
	SERVICE_PLAN_SELECTED,
	FIBER_STATUS_DETERMINED,
	SIGNUP_FORM_SUBMIT,
	OPEN_HEADER_TRAY,
	MOBILE_NAV_CLICK,
	FOOTER_NAV_CLICK,
	FOOTER_NAV_HOVER,
	TESTIMONIAL_CLICK,
	TESTIMONIAL_HOVER,
	CAROUSEL_SLIDE_CHANGED,
	FAQ_CLICK,
	FAQ_HOVER,
	BUSINESS_FORM_SUBMIT,
	CONTACT_CALL_US_CLICK,
	CONTACT_FORM_SUBMIT,
	LIVE_CHAT_WIDGET_CLICK,
	signupFormSubmitEvents
}
